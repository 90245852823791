<template>
  <div class="pt-4">
    <div class="card-tabs mb-6 pb-1 px-3 mx-n3">
      <div
        class="item mr-4"
        :class="{ active: activeTab === item.id }"
        v-for="(item, i) in tabs"
        :key="i"
        @click="activeTab = item.id"
      >
        <div
          class="tabs-title"
          v-html="item.title"
        ></div>
      </div>
    </div>

    <div
      class="todo-list mb-4"
      v-if="list"
    >
      <div
        class="item d-flex mb-2 justify-space-between"
        v-for="(item, i) in list"
        :key="i"
      >
        <v-checkbox
          hide-details
          class="ma-0"
          v-model="item.done"
          :label="item.title"
        ></v-checkbox>
        <v-btn
          @click="remove(item, i)"
          small
          icon
          class="todo-btn-delete"
        >
          <v-icon
            small
            color="red"
          >mdi-delete</v-icon>
        </v-btn>
      </div>
    </div>

    <div class="form d-flex">
      <v-text-field
        placeholder="Добавить новое задание"
        hide-details
        dense
        v-model="input"
      ></v-text-field>
      <v-btn
        small
        class="ml-3"
        @click="add"
        dark
        color="blue"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    form: {
      type: Object,
    },
  },
  data() {
    return {
      activeTab: 0,
      input: "",
      tabs: [
        {
          title: "Все задачи",
          id: 0,
        },
        {
          title: "Невыполненные",
          id: 1,
        },
        {
          title: "Выполненные",
          id: 2,
        },
      ],
    };
  },
  computed: {
    list() {
      if (!this.form.data.list) return [];
      if (!this.activeTab) return this.form.data.list;
      let done = false;
      if (this.activeTab === 2) {
        done = true;
      }
      return this.form.data.list.filter((o) => o.done === done);
    },
  },
  methods: {
    add() {
      if (!this.input) return;
      this.form.data.list.push({
        title: this.input,
        done: false,
      });
      this.input = "";
    },
    async remove(item, i) {
      const res = await this.$dialog.confirm({
        title: "Удалить",
        text: item.title + " ?",
        persistent: false,
      });
      if (!res) return;
      this.$delete(this.form.data.list, i);
    },
  },
};
</script>

<style lang="scss">
.todo-btn-delete {
  opacity: 0;
  transition: all 0.3s ease;
  &:hover {
    opacity: 1 !important;
  }
}

.todo-list {
  .item {
    &:hover {
      .todo-btn-delete {
        opacity: 0.5;
      }
    }
  }
}
</style>
